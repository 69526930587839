<template>
  <ClientOnly>
    <div v-if="isLoggedIn">
      <button class="px-2 py-3.5 lg:px-4" @mouseenter="open">
        <NuxtLink
          :to="getShopRoute('/account/profile')"
          class="flex items-center"
        >
          <IconUser class="size-5 stroke-1.5" />

          <span class="ml-3 hidden font-bold 2xl:inline-block">
            {{ user?.firstName }}
          </span>
        </NuxtLink>
      </button>
      <NavDropdown
        v-show="isOpen"
        @mouseleave="navigationDropdown.close()"
        @mouseenter="navigationDropdown.clearCloseDelay()"
      >
        <div class="px-10 pb-9 pt-8">
          <ul class="space-y-2.5 font-medium">
            <li>
              <strong>{{ $t('account.myAccount') }}</strong>
            </li>
            <li>
              <NuxtLink
                :to="getShopRoute('/account/profile')"
                class="flex items-center gap-3"
              >
                <IconUser class="w-4 stroke-1.5" />
                <span>{{ $t('account.myData') }}</span>
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                :to="getShopRoute('/account/address')"
                class="flex items-center gap-3"
              >
                <IconLocation class="w-4 stroke-1.5" />
                <span>{{ $t('account.address.title') }}</span>
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                :to="getShopRoute('/account/orders')"
                class="flex items-center gap-3"
              >
                <IconShoppingBag class="w-4" />
                <span>{{ $t('account.orderHistory') }}</span>
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                :to="getShopRoute('/account/downloads')"
                class="flex items-center gap-3"
              >
                <IconDocument class="w-4" />
                <span>{{ $t('account.material') }}</span>
              </NuxtLink>
            </li>
            <li v-if="isProfessional">
              <NuxtLink
                :to="getShopRoute('/account/brands')"
                class="flex items-center gap-3"
              >
                <IconUnlock class="w-4" />
                <span>{{ $t('account.myExclusiveBrands') }}</span>
              </NuxtLink>
            </li>
            <li>
              <button
                class="flex items-center gap-3 text-red-600"
                @click="logout"
              >
                <IconLogout class="mr-0.5 w-3.5" />
                <span>{{ $t('account.logout') }}</span>
              </button>
            </li>
          </ul>
        </div>
      </NavDropdown>
    </div>
    <div v-else class="mx-2 lg:mx-4">
      <NuxtLink :to="getShopRoute('/login')" class="2xl:hidden">
        <IconUser class="size-5 stroke-1.5" />
      </NuxtLink>
      <div class="hidden 2xl:block">
        <AppButton :to="getShopRoute('/login')" variant="secondary" size="sm">
          {{ $t('account.login') }}
        </AppButton>
      </div>
    </div>
  </ClientOnly>
</template>

<script setup lang="ts">
const { user, isLoggedIn, logout } = useUser()
const { isProfessional } = useCustomer(user)
const { getShopRoute } = useShopRouter()

const navigationDropdown = useNavigationDropdown()
const { active } = storeToRefs(navigationDropdown)

const isOpen = computed(() => active.value === 'auth')

const open = () => {
  if (window.innerWidth < 1024) {
    return
  }
  navigationDropdown.open('auth')
}
</script>
