<template>
  <NavContainer
    :dark-nipple="containsExclusiveBrands"
    :end="end"
    v-bind="$attrs"
    :class="{
      'w-full': fullwidth,
    }"
    @mouseenter="emit('entered', true)"
    @mouseleave="emit('left', true)"
  >
    <slot>
      <div
        class="flex flex-wrap text-black"
        :class="[fullwidth ? 'w-full' : 'max-w-[42.875rem]']"
      >
        <div
          v-for="(navItemLevel2, index) in itemsWithChildren"
          :key="navItemLevel2.id"
          class="flex flex-col items-start px-10 pb-9 pt-8"
          :class="[
            {
              'grow-0 bg-black text-white': hasExclusiveBrands(navItemLevel2),
            },
            { 'is-exclusive': containsExclusiveBrands && index === 1 },
          ]"
        >
          <NuxtLink
            v-if="'apiAlias' in navItemLevel2"
            :to="getCategoryRoute(navItemLevel2)"
            class="mb-2 text-sm font-bold"
            :class="{
              lined: !hasExclusiveBrands(navItemLevel2),
            }"
          >
            {{ getTranslatedProperty(navItemLevel2, 'name') }}
          </NuxtLink>
          <NuxtLink
            v-else
            :to="navItemLevel2.page.url"
            class="lined mb-2 text-sm font-bold"
          >
            {{ navItemLevel2.page.title }}
          </NuxtLink>

          <ul
            class="submenu flex-1 space-y-2"
            :class="{ 'pb-7': items.length > 1 }"
          >
            <li
              v-for="(navItemLevel3, level3Index) in navItemLevel2.children"
              :key="level3Index"
            >
              <div
                class="inline-flex items-center gap-2"
                :class="{
                  'border border-white px-2 py-1':
                    hasUserBrandAccess(navItemLevel3),
                }"
              >
                <IconUnlock
                  v-if="hasUserBrandAccess(navItemLevel3)"
                  class="w-4"
                />
                <IconLock v-else-if="isExclusive(navItemLevel3)" class="w-4" />
                <NuxtLink
                  v-if="'apiAlias' in navItemLevel3"
                  :to="getCategoryRoute(navItemLevel3)"
                  class="text-xs1 font-medium"
                  :class="{
                    lined: !hasExclusiveBrands(navItemLevel2),
                  }"
                >
                  {{ getTranslatedProperty(navItemLevel3, 'name') }}
                </NuxtLink>
                <NuxtLink
                  v-else
                  :to="navItemLevel3.page.url"
                  class="text-xs1 font-medium"
                  :class="{
                    lined: !hasExclusiveBrands(navItemLevel2),
                  }"
                >
                  {{ navItemLevel3.page?.title }}
                </NuxtLink>
              </div>
            </li>
          </ul>

          <div v-if="isExclusive(navItemLevel2)" class="hidden text-xs">
            <p class="mb-1 font-medium">{{ $t('locked') }}</p>
            <div class="flex gap-2">
              <IconLock class="w-4" />
              <NuxtLink :to="getShopRoute('/register')">{{
                $t('requestPermission')
              }}</NuxtLink>
            </div>
          </div>
        </div>
        <ul
          v-if="standaloneItems.length"
          class="mr-auto flex flex-col flex-wrap justify-start gap-4 px-10 pb-9 pt-8"
        >
          <li
            v-for="standaloneItem in standaloneItems"
            :key="standaloneItem.id"
          >
            <NuxtLink
              v-if="'apiAlias' in standaloneItem"
              :to="getCategoryRoute(standaloneItem)"
              class="lined mb-2 text-sm font-bold"
            >
              {{ getTranslatedProperty(standaloneItem, 'name') }}
            </NuxtLink>
            <NuxtLink
              v-else
              :to="standaloneItem.page.url"
              class="lined mb-2 text-sm font-bold"
            >
              {{ standaloneItem.page.title }}
            </NuxtLink>
          </li>
        </ul>
      </div>
    </slot>
  </NavContainer>
</template>

<script setup lang="ts">
import { getTranslatedProperty } from '@shopware-pwa/helpers-next'
import type { Schemas } from '#shopware'
import type { StatamicNavigationItem } from '~/types/statamic'
import type { CustomerCategory } from '~/types/shopware-custom'
import { useShopRouter } from '~/composables/useShopRouter'
import { useCustomer } from '~/composables/useCustomer'
import type { NavigationItem } from '~/stores/navigation'

const props = withDefaults(
  defineProps<{
    items?: NavigationItem[]
    end?: boolean
    fullwidth?: boolean
  }>(),
  {
    end: false,
    items: () => [],
    fullwidth: false,
  }
)

const emit = defineEmits(['entered', 'left'])

const { user } = useUser()
const { isProfessional } = useCustomer(user)
const { getCategoryRoute, getShopRoute } = useShopRouter()

const itemsWithChildren = computed(() =>
  props.items.filter((item) => item.children?.length)
)

const standaloneItems = computed(() =>
  props.items.filter((item) => !item.children?.length)
)

const containsExclusiveBrands = computed(() =>
  props.items.some((item) => item.children?.some((child) => isExclusive(child)))
)

const isExclusive = (
  category: Schemas['Category'] | StatamicNavigationItem
) => {
  return (
    'extensions' in category &&
    category?.extensions?.exclusiveCategory?.isExclusiveBrand
  )
}

const hasExclusiveBrands = (
  category: Schemas['Category'] | StatamicNavigationItem
) => category?.children?.some((child) => isExclusive(child))

const hasUserBrandAccess = (
  category: Schemas['Category'] | StatamicNavigationItem
) =>
  isProfessional.value &&
  isExclusive(category) &&
  user.value?.extensions?.customerCategories?.some(
    (customerCategory: CustomerCategory) =>
      customerCategory.categoryId === category.id
  )
</script>

<style scoped>
.is-exclusive .submenu {
  @apply columns-2;
}
</style>
